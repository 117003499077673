<template>
  <div class="faculty">
    <navBar></navBar>
    <div class="content">
      <h2 class="md-headline">Teachers</h2>
      <div class="head" v-if="showHead">
        <md-field>
          <label for="heading">Name</label>
          <md-select @md-selected="addPersonDiv = false" v-model="index" name="Heading" class="Heading" id="heading">
            <md-option v-for="(element, index) in persons" v-bind:key="index" :value="index">{{
                element.name
              }}
            </md-option>
          </md-select>
        </md-field>
        <md-button class="md-primary md-raised" @click="showAddPersonDiv()">Add Person</md-button>
      </div>

      <div class="personData" v-if="!addPersonDiv && index != null">
        <!-- Person -->
        <md-field>
          <label>Name (edit here)</label>
          <md-input v-model="persons[index].name"></md-input>
        </md-field>
        <div class="md-layout">
          <div class="md-layout-item md-size-20">
            <md-switch v-model="persons[index].published">Published</md-switch>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label>Importance</label>
              <md-input v-model="persons[index].importance" type="number"></md-input>
            </md-field>
          </div>
        </div>

        <md-field>
          <label>Category</label>
          <md-select v-model="persons[index].category">
            <md-option value="masterTeacher">Master Teacher</md-option>
            <md-option value="headCoach">Head Coach</md-option>
            <md-option value="languageCoach">Language Coach</md-option>
            <md-option value="pianist">Pianist</md-option>
            <md-option value="makeup">Make-Up Artists</md-option>
          </md-select>
        </md-field>
        <md-field>
          <label>Image</label>
          <md-input v-model="persons[index].image"></md-input>
        </md-field>
        <img :src="persons[index].image" style="max-height: 10em; width: auto"/>


        <table style="width: 100%">
          <tr>
            <td>
              <h2 class="md-title">Deutsch</h2>
              <md-field>
                <label>Subtitle</label>
                <md-input v-model="persons[index].de.subtitle"></md-input>
              </md-field>

              <md-field>
                <label>Content</label>
                <md-textarea v-model="persons[index].de.content" md-autogrow></md-textarea>
              </md-field>
            </td>

            <td>
              <h2 class="md-title">English</h2>
              <md-field>
                <label>Subtitle</label>
                <md-input v-model="persons[index].en.subtitle"></md-input>
              </md-field>

              <md-field>
                <label>Content</label>
                <md-textarea v-model="persons[index].en.content" md-autogrow></md-textarea>
              </md-field>
            </td>
          </tr>
        </table>

        <md-button class="md-primary md-raised" @click="putPerson(index)">Save</md-button>

        <md-button class="md-accent md-raised" @click="deletePerson(index)">Delete</md-button>

      </div>

      <div class="addPerson" v-if="addPersonDiv">
        <md-field>
          <label>Name</label>
          <md-input v-model="newPerson.name"></md-input>
        </md-field>
        <md-field>
          <label>Importance</label>
          <md-input v-model="newPerson.importance" type="number"></md-input>
        </md-field>
        <md-field>
          <label>Category</label>
          <md-select v-model="newPerson.category">
            <md-option value="masterTeacher">Master Teacher</md-option>
            <md-option value="headCoach">Head Coach</md-option>
            <md-option value="languageCoach">Language Coach</md-option>
            <md-option value="pianist">Pianist</md-option>
            <md-option value="makeup">Make-Up Artists</md-option>
          </md-select>
        </md-field>
        <md-field>
          <label>Image</label>
          <md-input v-model="newPerson.image"></md-input>
        </md-field>
        <img :src="newPerson.image" style="max-height: 10em; width: auto"/>


        <table style="width: 100%">
          <tr>
            <td>
              <h2 class="md-title">Deutsch</h2>
              <md-field>
                <label>Subtitle</label>
                <md-input v-model="newPerson.de.subtitle"></md-input>
              </md-field>

              <md-field>
                <label>Content</label>
                <md-textarea v-model="newPerson.de.content" md-autogrow></md-textarea>
              </md-field>
            </td>

            <td>
              <h2 class="md-title">English</h2>
              <md-field>
                <label>Subtitle</label>
                <md-input v-model="newPerson.en.subtitle"></md-input>
              </md-field>

              <md-field>
                <label>Content</label>
                <md-textarea v-model="newPerson.en.content" md-autogrow></md-textarea>
              </md-field>
            </td>
          </tr>
        </table>


        <md-dialog-confirm
            :md-active.sync="newPersonDialogActive"
            md-title="Do you want to add a new Person?"
            md-content="A new data set will be created."
            md-confirm-text="Save"
            md-cancel-text="Cancel"
            @d-cancel="newPersonDialogActive = false"
            @md-confirm="postNewPerson()"/>

        <md-button class="md-primary md-raised" @click="newPersonDialogActive = true">Add Person</md-button>

        <md-dialog-confirm
            :md-active.sync="newPersonCancelDialogActive"
            md-title="Do you want to delete all entered data?"
            md-content="All data you entered will be deleted."
            md-confirm-text="Delete"
            md-cancel-text="Cancel"
            @d-cancel="newPersonCancelDialogActive = false"
            @md-confirm="resetSite()"/>

        <md-button class="md-accent md-raised" @click="newPersonCancelDialogActive = true">Cancel</md-button>

      </div>

    </div>

    <text-edit title-user="Additional Text" title-intern-prop="additional_text" site-prop="faculty"></text-edit>

    <loading :active.sync="query"
             :can-cancel="true"
             :is-full-page="true"></loading>
  </div>
</template>
<script>

import navBar from '@/components/NavBar.vue';
import axios from 'axios'
import Loading from 'vue-loading-overlay';
import textEdit from '@/components/TextEdit.vue'
import config from '@/config/const.js'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "Faculty",
  data() {
    return {
      config: config,
      showHead: true,
      addPersonDiv: false,
      index: null,

      query: false,
      currentPersonName: '',


      newPersonDialogActive: false,
      newPersonCancelDialogActive: false,
      deletePersonDialogActive: false,

      persons: [],
      newPerson: {
        category: "",
        name: "",
        importance: 0,
        "de": {
          subtitle: "",
          content: ""
        },
        "en": {
          subtitle: "",
          content: ""
        },
        site: "faculty",
        image: ""
      }
    }
  },
  methods: {
    showAddPersonDiv() {
      this.addPersonDiv = true
      this.showHead = false
    },
    loadData: function () {
      const me = this
      axios.get(me.config.SERVER_ADDRESS + '/person')
          .then(function (response) {
            me.persons = response.data
            // console.log(me.list)
          })
          .catch(function (error) {
            alert("An Error occurred, please contact Ruben (ruben@kaiser.fyi):. \n" + error);
          });
    },
    deletePerson(index) {
      this.query = true;

      let me = this;
      axios.delete(me.config.SERVER_ADDRESS + '/person/' + me.persons[index]._id)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite();
            me.query = false;
          })
          .catch(function (error) {
            // console.log(error);
            me.query = false;
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          });
    },
    postNewPerson() {
      this.query = true;
      let me = this;
      // console.log(JSON.stringify(me.newList));
      axios.post(me.config.SERVER_ADDRESS + '/person', me.newPerson)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.resetSite()
            me.loadData()
          })
          .catch(function (error) {
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
          }).finally(() => {
        me.query = false;
      })
    },
    putPerson() {
      this.query = true;
      let me = this;
      // console.log(me.list[me.index]._id)
      // console.log(JSON.stringify(me.list[me.index]))
      axios.put(me.config.SERVER_ADDRESS + '/person/id/' + me.persons[me.index]._id, me.persons[me.index])
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            // console.log(response.data.message);
            me.loadData()
            me.query = false;
          })
          .catch(function (error) {
            me.query = false;
            alert(`${error}.\n\nReason:\n${error.response.data.message}\n\n If you need support with that, please contact ruben@kaiser.fyi.`);
            me.query = false;
          })
    },
    resetSite: function () {
      this.showHead = true
      this.addPersonDiv = false
      this.index = null

      this.query = false
      this.currentPersonName = ''


      this.newPersonDialogActive = false
      this.newPersonCancelDialogActive = false
      this.deletePersonDialogActive = false

      this.persons = []
      this.newPerson = {
        category: "",
        name: "",
        "de": {
          subtitle: "",
          content: ""
        },
        "en": {
          subtitle: "",
          content: ""
        },
        site: "faculty",
        image: ""
      }
      this.loadData()
    },

  },
  components: {
    navBar,
    Loading,
    textEdit
  },
  mounted() {
    this.loadData()
  }
}
</script>
<style scoped>
.content {
  margin: 2% 5% 0 5%;
}

.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

td {
  padding: 1%;
  width: 50%;
}

.noMargin {
  margin: 0px;
}
</style>
